import * as React from "react"

import Layout from "../components/layout"
import Seo from "../components/seo"

import Header from "../components/trial-header"
import Logos from "../components/logo-cloud"
import Features from "../components/trial-features"
import Video from "../components/video"
import FeaturesGrid from "../components/trial-features-grid"
import Cta from "../components/trial-logo-cta"
import Cta2 from "../components/cta"

const TrialPage = () => (
  <Layout trialNav>
    <Seo title="Property Inspection Reports | Start FREE trial today!" />
    <Header />
    <Logos />
    <Features />
    <Video videoLink="https://player.vimeo.com/video/703111656?" />
    <Cta />
    <FeaturesGrid />
    <Cta2
      trialCta
      header="start your free* trial today!"
      content="Looking to manage your rental property? You need Inspect Live."
      terms="* Free one month trial is activated when user logs into Inspect Live with their REI Forms Live/Realworks credentials. 
      Free trial can only be activated once per Agency account. To review trial access initiation date, see Addons in your REI Forms Live/Realworks Account Details area (principal users only)."
    />
  </Layout>
)

export default TrialPage
