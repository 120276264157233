import React from "react"

import { OutboundLink } from "gatsby-plugin-gtag-outbound"

import FormsLiveLogo from "../images/logos/fl_logo.svg"
import ReiFormsLiveLogo from "../images/logos/fl_logo_white.png"
import RealworksLogo from "../images/logos/realworks_logo_white.png"
import FormsLiveScreen from "../images/forms_live_feature.png"
import ReiFormsLiveScreen from "../images/rfl_screenshot.png"
import RealworksScreen from "../images/realworks_screenshot.png"

const TrialFeatures = () => {
  return (
    <div
      id="get-started"
      className="relative overflow-hidden bg-base-200 py-2 lg:py-32"
    >
      <div className="relative">
        <div className="relative mx-auto md:max-w-2xl lg:max-w-7xl py-24 px-6 sm:py-12 md:px-2 lg:px-8">
          <h1 className="text-4xl font-bold tracking-tight text-ivoryMain sm:text-5xl lg:text-6xl">
            <span className="block xl:inline">Select your </span>{" "}
            <span className="block text-primary xl:inline">
              platform of choice
            </span>
          </h1>
          <p className="mt-6 max-w-4xl text-lg md:text-xl text-gray-200 leading-tight">
            Property Managers can now conduct inspections reports in under 10
            minutes, from any device, thanks to Inspect Live. Customise reports
            to suit any building layout, use tenant specifications, add rooms
            and items quickly and easily - all synced to your Forms Live, REI
            Forms Live or Realworks account.
          </p>
        </div>

        {/* Forms Live Feature */}
        <div className="lg:mx-auto lg:grid lg:max-w-7xl lg:grid-flow-col-dense lg:grid-cols-2 lg:gap-24 md:8 md:py-12 lg:py-24 lg:px-8">
          <div className="mx-auto max-w-2xl px-6 md:px-0 lg:mx-0 lg:max-w-none lg:py-32 lg:px-0">
            <div>
              <div>
                <img
                  src={FormsLiveLogo}
                  alt="Forms Live - vic forms leading real estate forms builder."
                  className="flex items-center justify-center h-16"
                  aria-hidden="true"
                />
              </div>

              <div className="mt-6">
                <h2 className="text-sm md:text-lg uppercase font-semibold tracking-tight text-primary">
                  Victorian Property Managers
                </h2>
                <p className="py-4 text-lg md:text-xl text-gray-200 leading-tight">
                  Discover why we are trusted by the biggest names in Victorian
                  real estate. Exclusively for the Victorian market. Using
                  online tools to house important information means no risk of
                  losing paperwork, no printer or scanner required, while less
                  paper is a plus for the planet. Our current CRM partners
                  easily integrate with our forms, where the possibilities for
                  integration are endless.
                </p>
                <div className="mt-6">
                  <OutboundLink
                    href="https://formslive.com.au/free-trial#sign-up"
                    target="_blank"
                    rel="noreferrer"
                    className="btn btn-primary hover:bg-transparent hover:border-blueMain hover:text-blueHighlight text-gray-900 rounded-full px-8"
                  >
                    start free trial
                  </OutboundLink>
                </div>
              </div>
            </div>
          </div>
          <div className="mt-12 sm:mt-16 lg:mt-0">
            <div className="-mr-48 pl-4 sm:pl-6 md:-mr-16 lg:relative lg:m-0 lg:h-full lg:px-0">
              <img
                className="hidden md:block w-full rounded-xl shadow-xl ring-1 ring-ivoryMain ring-opacity-5 lg:absolute lg:left-0 lg:h-full lg:w-auto lg:max-w-none"
                src={FormsLiveScreen}
                alt="Forms Live user interface - The best Victorian real estate software solution for compliant online forms."
              />
            </div>
          </div>
        </div>
      </div>

      {/* REI Forms Live */}
      <div className="mt-24 md:mt-12 lg:mt-24">
        <div className="lg:mx-auto lg:grid lg:max-w-7xl lg:grid-flow-col-dense lg:grid-cols-2 lg:gap-24 lg:px-8 ">
          <div className="mx-auto max-w-2xl px-6 md:px-0 lg:col-start-2 lg:mx-0 lg:max-w-none lg:py-32 lg:px-0">
            <div>
              <div>
                <img
                  src={ReiFormsLiveLogo}
                  alt="REI, the Real Estate Intstiute, Forms Live Logo."
                  className="flex items-center justify-center h-16"
                  aria-hidden="true"
                />
              </div>
              <div className="mt-6">
                <h2 className="text-sm md:text-lg uppercase font-semibold tracking-tight text-primary">
                  REI Members - reiwa, reisa, reinsw, reiact, reint, reit
                </h2>
                <p className="py-4 text-lg text-gray-200 leading-tight">
                  Complete your agreements and forms online with REI Forms Live.
                  Enter your data once then let REI Forms Live do the heavy
                  lifting. Portable in every sense of the word, don't be
                  shackled to the desktop - go anywhere, prepare and complete.
                  REI Forms Live, designed with you in mind. If you are a rental
                  or commercial Property Manager, get started with REI Forms
                  Live today!
                </p>
                <div className="mt-6">
                  <OutboundLink
                    href="https://www.reiformslive.com.au/#contact"
                    target="_blank"
                    rel="noreferrer"
                    className="btn btn-primary hover:bg-transparent hover:border-blueMain hover:text-blueHighlight text-gray-900 rounded-full px-8"
                  >
                    start free trial
                  </OutboundLink>
                </div>
              </div>
            </div>
          </div>
          <div className="mt-12 sm:mt-16 lg:col-start-1 lg:mt-0">
            <div className="-ml-48 pr-4 sm:pr-6 md:-ml-16 lg:relative lg:m-0 lg:h-full lg:px-0">
              <img
                className="hidden md:block w-full rounded-xl shadow-xl ring-1 ring-black ring-opacity-5 lg:absolute lg:right-0 lg:h-full lg:w-auto lg:max-w-none"
                src={ReiFormsLiveScreen}
                alt="REI Forms Live user interface - real esate platform for reiwa, reisa, reinsw, reiact, reint and reit."
              />
            </div>
          </div>
        </div>
      </div>

      {/* Realworks Feature */}
      <div className="md:mt-12 lg:mt-24 lg:mx-auto lg:grid lg:max-w-7xl lg:grid-flow-col-dense lg:grid-cols-2 lg:gap-24 lg:px-8 py-12 lg:py-24">
        <div className="mx-auto max-w-2xl px-6 md:px-0 lg:mx-0 lg:max-w-none lg:py-32 lg:px-0">
          <div>
            <div>
              <img
                src={RealworksLogo}
                alt="REIQ - Real Estate Institute of Queensland - Realworks forms platform logo"
                className="flex items-center justify-center h-16"
                aria-hidden="true"
              />
            </div>
            <div className="mt-6">
              <h2 className="text-sm md:text-lg uppercase font-semibold tracking-tight text-primary">
                REIQ Property management Members
              </h2>
              <p className="py-4 text-lg text-gray-200 leading-tight">
                Realworks is the leading real estate forms software in
                Queensland. Offering contracts, agreements and forms, all
                capturing the latest Queensland regulations and legal
                requirements for the sale, auction and property management of
                residential, commercial and rural real estate. Streamline your
                workflow today, and get started with Realworks!
              </p>
              <div className="mt-6">
                <OutboundLink
                  href="https://www.realworks.com.au/#subscribe"
                  target="_blank"
                  rel="noreferrer"
                  className="btn btn-primary hover:bg-transparent hover:border-blueMain hover:text-blueHighlight text-gray-900 rounded-full px-8"
                >
                  start free trial
                </OutboundLink>
              </div>
            </div>
          </div>
        </div>
        <div className="mt-12 sm:mt-16 lg:mt-0">
          <div className="-mr-48 pl-4 sm:pl-6 md:-mr-16 lg:relative lg:m-0 lg:h-full lg:px-0">
            <img
              className="hidden md:block w-full rounded-xl shadow-xl ring-1 ring-black ring-opacity-5 lg:absolute lg:left-0 lg:h-full lg:w-auto lg:max-w-none"
              src={RealworksScreen}
              alt="Realworks user interface - the online agreements and forms product designed to make everyday business easier for real estate professionals."
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default TrialFeatures
