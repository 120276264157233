import React from "react"

import {
  PuzzleIcon,
  LightningBoltIcon,
  SparklesIcon,
  StatusOfflineIcon,
  ShieldCheckIcon,
  BadgeCheckIcon,
  ClipboardCheckIcon,
  PencilAltIcon,
} from "@heroicons/react/outline"

const features = [
  {
    name: "Fast & Reliable",
    description:
      " Inspect Live helps manage the inspection process from start to finish. Create customised visual reports in a matter of minutes.",
    icon: LightningBoltIcon,
  },
  {
    name: "Powerful & Flexible",
    description:
      "You're in control with Inspect Live: add custom rooms, record unique items, re-use or modify tenant data to create reports for any property type.",
    icon: SparklesIcon,
  },
  {
    name: "Seamless Integration",
    description:
      "Inspect Live integrates seamlessly with your REI Forms Live/Realworks subscription - the online form creation tool that creates more time in your day.",
    icon: PuzzleIcon,
  },
  {
    name: "Offline First",
    description:
      "Inspect Live makes it easy to create and complete forms entirely offline. Then, when it suits you, securely upload your forms to Forms Live, REl Forms Live or Realworks.",
    icon: StatusOfflineIcon,
  },
  {
    name: "Legally Compliant",
    description:
      "Inspect Live is legally compliant in all states and territories, and endorsed by REINSW, REISA, REIWA, REINT, REIT, REIQ AND REIACT.",
    icon: BadgeCheckIcon,
  },
  {
    name: "Security",
    description:
      "Inspect Live is safe and secure; you choose who to share information with.",
    icon: ShieldCheckIcon,
  },
  {
    name: "Support & Feedback",
    description:
      "Inspect Live gives you access to dedicated, responsive support.",
    icon: ClipboardCheckIcon,
  },
  {
    name: "Fully Customisable",
    description:
      "Customise your reports to suit any property, use tenant data, rooms and items quickly and easily.",
    icon: PencilAltIcon,
  },
]

const TrialGrid = () => {
  return (
    <div className="bg-base-200 px-4 md:px-4">
      <div className="mx-auto max-w-4xl px-4 py-16 sm:px-6 sm:pt-20 sm:pb-24 lg:max-w-7xl lg:px-8 lg:pt-24">
        <h1 className="text-3xl font-bold capitalize tracking-tight text-ivoryMain sm:text-4xl md:text-5xl">
          <span className="block xl:inline">inspect live</span>{" "}
          <span className="block text-primary xl:inline">
            built for efficiency
          </span>
        </h1>
        <p className="mt-4 max-w-3xl text-base md:text-lg text-ivoryMain leading-tight">
          Need something that meets the needs of your organisation but without
          compromising on quality and security? Checkout the feature to see why
          Inspect Live is what your business needs.
        </p>
        <div className="mt-12 grid grid-cols-1 gap-x-6 gap-y-12 sm:grid-cols-2 lg:mt-16 lg:grid-cols-4 lg:gap-x-8 lg:gap-y-16">
          {features.map(feature => (
            <div key={feature.name}>
              <div>
                <span className="flex h-12 w-12 items-center justify-center rounded-full bg-gradient-to-t from-blueDark via-blueLight to-ivoryMain">
                  <feature.icon
                    className="h-6 w-6 text-base-200"
                    aria-hidden="true"
                  />
                </span>
              </div>
              <div className="mt-6">
                <h3 className="text-xl lg:text-lg font-bold text-primary">
                  {feature.name}
                </h3>
                <p className="mt-2 pr-4 md:pr-8 text-md lg:text-sm text-ivoryMain text-start md:text-start leading-tight">
                  {feature.description}
                </p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}

export default TrialGrid
