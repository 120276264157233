import * as React from "react"

import { Link } from "gatsby"

import PropTech2021 from "../images/Proptech_Awards_2021.png"
import PropTech2022 from "../images/Proptech_Awards_2022.png"

const TrialHeader = () => {
  return (
    <main
      id="trial-header"
      className="bg-base-200 mx-auto py-12 md:py-16 lg:py-24 max-w-7xl px-4"
    >
      <div className="text-center">
        <h1 className="text-3xl font-bold capitalize tracking-tight text-ivoryMain sm:text-4xl md:text-5xl">
          <span className="block xl:inline">property inspection </span>{" "}
          <span className="block text-primary xl:inline">forms on demand</span>
        </h1>
        <p className="mx-auto py-6 leading-tight max-w-md text-sm text-gray-200 md:mt-5 md:max-w-2xl lg:max-w-3xl md:text-2xl lg:text-xl">
          Complete the entire property inspection process from start to finish,
          from anywhere, even offline with Inspect Live. Legally compliant in
          all states and territories, Inspect Live seamlessly integrates with
          Forms Live, REI Forms Live and Realworks.
        </p>

        <div className="bg-base-200">
          <div className="mx-auto max-w-7xl py-6 px-4 sm:py-16 sm:px-6 lg:px-8 lg:py-12">
            <dl className="text-center mx-auto grid max-w-3xl grid-cols-3 gap-8">
              <div className="flex flex-col">
                <dt className="order-2 text-sm leading-tight md:text-xl lg:text-2xl lg:mt-4 font-medium text-primary">
                  Downloads
                </dt>
                <dd className="order-1 text-3xl md:text-5xl font-bold tracking-tight text-ivoryMain">
                  10K
                </dd>
              </div>
              <div className="flex flex-col sm:mt-0">
                <dt className="order-2 text-sm leading-tight md:text-xl lg:text-2xl lg:mt-4 font-medium text-primary">
                  Australian Agencies
                </dt>
                <dd className="order-1 text-3xl md:text-5xl font-bold tracking-tight text-ivoryMain">
                  8.5K
                </dd>
              </div>
              <div className="flex flex-col sm:mt-0">
                <dt className="order-2 text-sm leading-tight md:text-xl lg:text-2xl lg:mt-4 font-medium text-primary">
                  Forms Created
                </dt>
                <dd className="order-1 text-3xl md:text-5xl font-bold tracking-tight text-ivoryMain">
                  42M
                </dd>
              </div>
            </dl>
          </div>
        </div>
      </div>

      <div className="py-8 hidden  sm:grid grid-cols-2 gap-12 md:grid-cols-2 lg:mt-0 lg:grid-cols-2">
        <div className="hiden col-span-1 flex justify-end py-8 px-8">
          <img
            className="max-h-52"
            src={PropTech2021}
            alt="Proptech gold shield - Forms Live was a nominee in the PropTech Awards 2021 for Operations and Administration under the Establish Supplier category"
          />
        </div>
        <div className="col-span-1 flex justify-start py-8 px-8">
          <img
            className="max-h-52"
            src={PropTech2022}
            alt="Proptech platinum shield - Forms Live was a nominee in the PropTech Awards 2022 for Efficiency and Optimisation under the Establish Supplier category"
          />
        </div>
      </div>

      <div className="mx-auto py-12 pb-8 max-w-lg flex justify-center md:py-8 lg:py-12">
        <div className="rounded-md ">
          <Link
            to="#get-started"
            className="btn btn-primary hover:bg-transparent hover:border-blueMain lg:text-md hover:text-ivoryMain text-gray-900 rounded-full px-4 md:px-8"
          >
            Start free trial
          </Link>
        </div>
        <div className="rounded-md ml-6">
          <Link
            to="#demo"
            className="btn btn-primary bg-transparent border-blueMain lg:text-md text-ivoryMain hover:text-gray-900 rounded-full px-8"
          >
            view demo
          </Link>
        </div>
      </div>
    </main>
  )
}

export default TrialHeader
