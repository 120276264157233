import React from "react"

const Video = ({ videoLink }) => {
  return (
    <div id="demo">
      <div className="bg-base-200 mx-auto md:py-12 lg:py-2 lg:pb-12 max-w-7xl px-4 sm:px-8 md:px-12 lg:px-24">
        <div className="text-center">
          <h1 className="text-4xl font-bold capitalize tracking-tight text-ivoryMain sm:text-5xl md:text-6xl">
            <span className="block xl:inline">get started</span>{" "}
            <span className="block text-primary xl:inline">under 10 min.</span>
          </h1>
          <p className="mx-auto mt-3 max-w-md px-2 text-base text-justify md:text-center text-gray-200 sm:text-lg md:mt-5 md:max-w-3xl md:text-xl leading-tight">
            Inspect Live is the only inspection app you'll ever need. Inspect
            Live helps Property Managers conduct inspections reports, from start
            to finish, customisable to any building layout with unlimited
            photos. Watch and see how easy it is to get started!
          </p>
        </div>

        <div className="relative py-8 rounded-xl shadow-xl">
          <div className="absolute inset-0 flex flex-col" aria-hidden="true">
            <div className="flex-1" />
            <div className="w-full flex-1" />
          </div>
          <div className="aspect-w-16 aspect-h-9">
            <iframe
              title="Dynamic Methods Forms Live Promo Video"
              src={videoLink}
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            ></iframe>
          </div>
          <div className="text-center">
            <p className="mx-auto mt-3 max-w-md text-xs md:text-sm text-gray-200 sm:text-md md:mt-5 md:max-w-xl lg:text-lg">
              Created by Dynamic Methods, the development team behiond Forms
              Live, REI Forms Live & Realworks.
            </p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Video
