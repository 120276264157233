import React from "react"

import { OutboundLink } from "gatsby-plugin-gtag-outbound"

import FormsLiveLogo from "../images/logos/fl_logo_dark.png"
import ReiFormsLiveLogo from "../images/logos/rei_fl_logo_dark.png"
import RealworksLogo from "../images/logos/realworks_logo_dark.png"

const TrialCta = () => {
  return (
    <div id="sign-up" className="bg-ivoryMain">
      <div className="mx-auto max-w-7xl py-8 md:py-16 px-4 sm:py-24 sm:px-6 lg:px-8">
        <div className="lg:grid lg:grid-cols-2 lg:gap-4">
          <h2 className="mx-auto max-w-md text-center text-xl md:text-3xl font-bold tracking-tight text-base-200 lg:max-w-xl lg:text-left ">
            start your <span className="text-primary font-extrabold">FREE</span> trial today!
          </h2>
          <div className="mt-8 flow-root self-center lg:mt-0">
            <div className="-mt-4 -ml-8 flex flex-wrap justify-between lg:-ml-4">
              <div className="mt-4 ml-8 flex flex-shrink-0 flex-grow justify-center lg:ml-4 lg:flex-grow-0">
                <OutboundLink
                  href="https://formslive.com.au/free-trial#sign-up"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img className="h-6 md:h-12" src={FormsLiveLogo} alt="Forms Live - the best vic forms for real estate professionals." />
                </OutboundLink>
              </div>
              <div className="mt-4 ml-8 flex flex-shrink-0 flex-grow justify-center lg:ml-4 lg:flex-grow-0">
                <OutboundLink
                  href="https://www.reiformslive.com.au/#contact"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img className="h-6 md:h-12" src={ReiFormsLiveLogo} alt="REI Forms Live - Australian real estate forms" />
                </OutboundLink>
              </div>
              <div className="mt-4 ml-8 flex flex-shrink-0 flex-grow justify-center lg:ml-4 lg:flex-grow-0">
                <OutboundLink
                  href="http://www.realworks.com.au/#subscribe"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img className="h-6 md:h-12" src={RealworksLogo} alt="REIQ - Realworks is the real estate institute forms platform for Queensland" />
                </OutboundLink>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default TrialCta
